import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import PrivateRoute from "./components/core/PrivateRoute";
import GoogleCallback from "./components/google-auth/GoogleCallback";
import InstallPWA from "./pages/install";
import SignupPage from "./pages/SignupPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ShopPage from "./pages/ShopPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ShippingReturnPolicyPage from "./pages/ShippingReturnPolicyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ContactPage from "./pages/ContactPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import SearchResultPage from "./pages/SearchResultPage";
import CartPage from "./pages/CartPage";
import AccountPage from "./pages/AccountPage";
import OrderConfirmedPage from "./pages/OrderConfirmedPage";
import BlogPage from "./pages/BlogPage";
import FAQPage from "./pages/FAQPage";
import ErrorPage from "./pages/ErrorPage";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes with Main Layout */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="account/signup" element={<SignupPage />} />
        <Route path="account/login" element={<LoginPage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/shop/:category" element={<ShopPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/shipping-and-return-policy"
          element={<ShippingReturnPolicyPage />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route
          path="/product/:slug"
          element={<ProductDetailsPage />}
        />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route path="/search" element={<SearchResultPage />} />
        <Route path="/install" element={<InstallPWA />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="*" element={<ErrorPage />} />

        <Route element={<PrivateRoute />}>
          <Route path="account/:tabName?" element={<AccountPage />} />
        </Route>
      </Route>

      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/order-confirmed" element={<OrderConfirmedPage />} />
      </Route>
      {/* Google OAuth Callback Route */}
      <Route path="/auth/google/callback" element={<GoogleCallback />} />
    </Routes>
  );
};

export default AppRoutes;
