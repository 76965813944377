import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import { useDispatch } from 'react-redux';
import { removeAddress, setDefaultAddress } from '../../redux/slices/addressSlice';
import { setSelectedAddress } from '../../redux/slices/orderSlice';

const AddressItem = ({ address, onEdit, enableRadio, selectedAddressId, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(address);
    handleMoreClose();
  };

  const handleDelete = () => {
    dispatch(removeAddress(address.id));
    handleMoreClose();
  };

  const handleMakeDefault = () => {
    dispatch(setDefaultAddress(address.id));
    handleMoreClose();
  };
  

  const handleChange = () =>{
    onSelect(address.id);
    dispatch(setSelectedAddress(address));

  }

  useEffect(() => {
    if (address.is_default === 1 && selectedAddressId !== address.id) {
      onSelect(address.id);
      dispatch(setSelectedAddress(address));
    }
  }, [address, selectedAddressId, onSelect, dispatch]);

  return (
    <Card variant="outlined" sx={{ mb: 2, display: 'flex', alignItems: 'center', boxShadow: 2 }}>
      {enableRadio && (
        <Radio
          checked={selectedAddressId === address.id}
          onChange={handleChange}
        />
      )}
      <Avatar sx={{ m: 2, bgcolor: 'primary.main' }}>
        <HomeIcon />
      </Avatar>
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body1" fontWeight={500}>{address.user_name}</Typography>
        <Typography variant="body2">{address.building_name_area}, {address.landmark}</Typography>
        <Typography variant="body2">{address.city} - {address.pincode}</Typography>
        <Typography variant="body2">{address.state}, {address.country}</Typography>
        <Typography variant="body2">{address.phone_number}</Typography>
        {address.is_default === 1 && (
          <Chip
            label="Default"
            color="primary"
            size="small"
            sx={{ mt: 1, color: "white" }}
          />
        )}
      </CardContent>

      <CardActions>
        <IconButton onClick={handleMoreClick} edge="end" aria-label="more">
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMoreClose}>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          {/* {
            !address.is_default && (<> */}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
             <MenuItem onClick={handleMakeDefault}>Make Default</MenuItem>
              {/* </>
            )
          } */}
          
         
        </Menu>
      </CardActions>
    </Card>
  );
};

export default AddressItem;
