import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CouponModal from './CouponModal';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';
import Confetti from 'react-confetti';
import AddressModal from './AddressModal';
import PaymentButton from '../../pages/PaymentButton';
import FreeDelivery from './cart-drawer/FreeDelivery';


export default function CartSummary() {
  const localCart = useSelector(state => state.cart.localCart);
  const serverCart = useSelector(state => state.cart.serverCart);
  const { isAuthenticated } = useSelector(state => state.auth);
  const cart = isAuthenticated ? serverCart : localCart;
  const [note, setNote] = useState("");
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const { addresses } = useSelector(state => state.address);
  const { selectedCoupon } = useSelector(state => state.order);
  const [coupon, setCoupon] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isFreeDelivery, setIsFreeDelivery] = useState(false);


  const { selectedAddress } = useSelector(state => state.order);

  const defaultAddress = addresses.find(address => address.is_default);

  const deliveryAddress = addresses.find(address => address.id === selectedAddress);

  const [selectedAddressId, setSelectedAddressId] = useState(selectedAddress || '');

  const deliveryCharge = isFreeDelivery ? 0 : 50;




  useEffect(() => {
    if (!selectedAddressId && defaultAddress) {
      setSelectedAddressId(defaultAddress.id);
    }
  }, [defaultAddress, selectedAddressId])


  useEffect(() => {
    if (!coupon && selectedCoupon) {
      setCoupon(selectedCoupon);
    }
  }, [selectedCoupon])



  const calculateDiscount = () => {
    const discountAmount = parseInt(coupon?.discount_amount) || 0;
    return coupon
      ? coupon.discount_type === "percentage"
        ? Math.round(((cart.total / 100) * discountAmount))
        : Math.round(discountAmount)
      : 0;
  };



  const calculateGrandTotal = () => {
    return Math.round(cart.total + deliveryCharge - calculateDiscount());
  };

 



  const handleCouponSelection = (selectedCoupon) => {
    if (selectedCoupon) {
      setCoupon(selectedCoupon);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    } else {
      setCoupon(null);
    }
  };

  return (
    <Box>

      {showConfetti && <Confetti width={400} height={window.innerHeight} />}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {
          isAuthenticated && (
            <>

              <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <img src="/coupon.svg" width={40} alt="coupon" />
                  {coupon ? (
                    <Box>

                      <Typography variant="body2" fontWeight={500}>Coupon Applied </Typography>


                      <Typography sx={{color:"green"}} fontWeight={500} variant='caption'>
                        You saved additional ₹{calculateDiscount()}
                      </Typography>
                    </Box>
                  ) : (<Typography variant='body2' fontWeight={"bold"}>Add Coupon</Typography>)

                  }

                </Box>


                <Button size='small' variant="outlined" color="primary" onClick={() => setIsCouponModalOpen(true)}>
                  {coupon ? "Change" : "Add"}
                </Button>
              </Box>
            </>
          )
        }

        <FreeDelivery cartTotal={cart.total} isFreeDelivery={isFreeDelivery} onChange={setIsFreeDelivery} />


      </Box>


      <Box sx={{ py: 2 }}>
        <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>Bill details</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ReceiptIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Sub Total</Typography>
          </Box>
         <Typography color={"green"} fontWeight={"bold"} variant='body2'>₹{Math.round(cart.total)}</Typography>
          
          
          
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Delivery Charge</Typography>
          </Box>
          <Typography variant='body2'>{isFreeDelivery ? <span style={{color:"green", fontWeight:"bold"}}>FREE</span> : `₹ ${deliveryCharge}`}</Typography>
        </Box>

        {coupon && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <DiscountIcon sx={{ fontSize: "1rem" }} />
              <Typography variant='body2'>Coupon Discount</Typography>
            </Box>
            <Typography variant='body2' sx={{color:"green", fontWeight:"bold"}}>- ₹ {calculateDiscount()}</Typography>
          </Box>
        )}
<Divider flexItem/>
        <Box sx={{ mt:1,display: "flex", justifyContent: "space-between" }}>
          <Typography variant='body1'  sx={{fontWeight:"bold"}}>Grand Total</Typography>
          <Typography variant='body1' sx={{fontWeight:"bold",color:"green"}}>₹ {calculateGrandTotal()}</Typography>
        </Box>
      </Box>

      <Divider flexItem sx={{ borderStyle: "dotted" }} />

      {
        isAuthenticated && (
          <Box sx={{ my: 2 }}>
            <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>Delivery Address</Typography>

            {
              deliveryAddress ? (
                <Box>
                  <Typography variant='body2'>{deliveryAddress.building_name_area}, near {deliveryAddress.landmark},     {deliveryAddress.city}, {deliveryAddress.pincode}, {deliveryAddress.state}
                    <br />{deliveryAddress.user_name} <br />Phone number: {deliveryAddress.phone_number}</Typography>

                  <Button variant='contained' size='small' sx={{ color: "white", borderRadius: "1.2rem", mt: 1, fontSize: "0.7rem" }} onClick={() => setIsAddressModalOpen(true)}>Change Address</Button>
                </Box>


              ) : <Typography variant='body2'>No address selected</Typography>
            }
          </Box>
        )
      }

      <Divider flexItem sx={{ borderStyle: "dotted" }} />

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Add a note to your order:
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add your note here..."
        />
      </Box>

      <CouponModal
        open={isCouponModalOpen}
        handleClose={() => setIsCouponModalOpen(false)}
        onSelectCoupon={handleCouponSelection}
      />

      <Box sx={{ mt: 2 }}>
        {
          isAuthenticated ? (

            deliveryAddress ? <PaymentButton billDetails={{
              cartTotal: cart.total,
              deliveryCharge,
              discount: calculateDiscount(),
              grandTotal: calculateGrandTotal(),
            }} selectedAddress={selectedAddressId} orderNote={note} selectedCoupon={coupon ? coupon.coupon_code : ''} /> : (
              <Button
                onClick={() => setIsAddressModalOpen(true)}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ color: "white" }}
              >
                Add address to proceed
              </Button>
            )

          ) : (
            <Link to={"/account/login"}>
              <Button variant='contained' fullWidth sx={{ color: "white" }}>Login to proceed</Button>
            </Link>

          )
        }


      </Box>
      <AddressModal selectedAddressId={selectedAddressId} onChange={setSelectedAddressId} open={isAddressModalOpen} onClose={setIsAddressModalOpen} />
    </Box>
  );
}
