import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';

export default function TermsAndConditionsPage() {
    return (
        <Container
            maxWidth="md"
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
        >
            <Box>
                <Typography variant="h4" gutterBottom align="center">
                    Terms and Conditions
                </Typography>
                <Divider style={{ marginBottom: '2rem' }} />

                <Typography variant="h6" paragraph>
                    <strong>Last Updated:</strong> 07/11/2024
                </Typography>

                <Typography variant="body1" paragraph>
                    Welcome to VeginGo! These Terms and Conditions govern your
                    use of our website, and the services we provide. By
                    accessing or using our website, you agree to comply with
                    these terms. Please read them carefully.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    By using our website, you agree to be bound by these Terms
                    and Conditions. If you do not agree with any part of these
                    terms, you must not use our website or services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Changes to the Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to modify, update, or change these
                    Terms at any time. Any changes will be posted on this page
                    with an updated date. Please review this page periodically
                    for updates.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. User Responsibilities
                </Typography>
                <Typography variant="body1" paragraph>
                    As a user of the VeginGo website, you agree to:
                    <ul>
                        <li>
                            Provide accurate and truthful information when
                            creating an account or making a purchase.
                        </li>
                        <li>
                            Maintain the confidentiality of your account
                            credentials.
                        </li>
                        <li>
                            Use the website for lawful purposes only and in
                            accordance with applicable local, state, and
                            national laws.
                        </li>
                        <li>
                            Not engage in any activity that could harm, disable,
                            or interfere with the functionality of our website.
                        </li>
                    </ul>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    4. Product Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We strive to provide accurate descriptions and images for
                    all products. However, we do not guarantee that the
                    information on the website is always accurate, complete, or
                    error-free. Prices and availability are subject to change
                    without notice.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Orders and Payments
                </Typography>
                <Typography variant="body1" paragraph>
                    When placing an order, you agree to provide accurate billing
                    and shipping information. Payments are processed securely
                    through third-party payment processors. By completing an
                    order, you confirm your intention to purchase the product at
                    the listed price.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Shipping and Delivery
                </Typography>
                <Typography variant="body1" paragraph>
                    We make every effort to ensure timely delivery of your
                    orders. However, we are not responsible for delays caused by
                    third-party couriers, weather conditions, or any other
                    factors outside of our control. Please review our shipping
                    policy for further details.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Returns and Refunds
                </Typography>
                <Typography variant="body1" paragraph>
                    Our return and refund policy is outlined on our website.
                    Please refer to the Return and Refund section for more
                    information on how to return products and request a refund.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. Intellectual Property
                </Typography>
                <Typography variant="body1" paragraph>
                    All content on this website, including text, images, logos,
                    trademarks, and software, is the property of VeginGo and is
                    protected by copyright and other intellectual property laws.
                    You may not use, reproduce, or distribute any content from
                    this website without prior written permission.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    To the fullest extent permitted by law, VeginGo will not be
                    liable for any direct, indirect, incidental, special, or
                    consequential damages arising from your use of the website
                    or services. This includes, but is not limited to, damages
                    related to the loss of data, loss of profits, or damage to
                    your device.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Privacy and Data Collection
                </Typography>
                <Typography variant="body1" paragraph>
                    Your use of our website is governed by our Privacy Policy.
                    By using the website, you consent to our data collection
                    practices as described in the Privacy Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    11. Governing Law
                </Typography>
                <Typography variant="body1" paragraph>
                    These Terms and Conditions will be governed by and construed
                    in accordance with the laws of Uttar Pradesh, India. Any
                    disputes arising from these terms shall be subject to the
                    exclusive jurisdiction of the courts in Lucknow.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    12. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about these Terms and
                    Conditions, please contact us at vegingo.team@gmail.com or
                    8765126842 .
                </Typography>
            </Box>
        </Container>
    );
}
