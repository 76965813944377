import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VerifiedIcon from "@mui/icons-material/Verified";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const Features = () => {
  const features = [
    {
      icon: <LocalShippingIcon fontSize="large" color="success" />,
      title: "Fast Delivery",
      description: "Get fresh vegetables and fruits delivered to your doorstep quickly.",
    },
    {
      icon: <AccessTimeIcon fontSize="large" color="success" />,
      title: "Freshness Guaranteed",
      description: "We deliver only the freshest produce, straight from the farm.",
    },
    {
      icon: <VerifiedIcon fontSize="large" color="success" />,
      title: "Quality Assured",
      description: "Our products are handpicked and quality-checked for your satisfaction.",
    },
    {
      icon: <SupportAgentIcon fontSize="large" color="success" />,
      title: "24/7 Customer Support",
      description: "Need help with your order? We're here for you anytime.",
    },
  ];

  return (
    <Container sx={{ py: 4, px: 2, backgroundColor: "#f9f9f9", mt:10}}>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} textAlign="center" sx={{
            "&:hover .icon": {
              transform: "rotateY(360deg) scale(1.2)",
            },
          }}>
            <Box
              className="icon"
              sx={{
                display: "inline-block",
                mb: 2,
                transition: "transform 0.6s ease",
                transformStyle: "preserve-3d", 

              }}
            >
              {feature.icon}
            </Box>
            <Typography variant="h6" component="div" gutterBottom>
              {feature.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {feature.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
