import React from 'react';
import Grid from '@mui/material/Grid';
import Map from '../../map/Map';
import AddressFormFields from './AddressFormFields';


const AddressForm = ({ onClose, editingAddress }) => {


    return (
    
            <Grid container columnSpacing={2} rowGap={5}>
                <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }}>
                    <Map />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AddressFormFields
                        editingAddress={editingAddress}
                        onClose={onClose}
                    />
                </Grid>
            </Grid>
        
    );
};

export default AddressForm;
