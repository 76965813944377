import React, { useState } from "react";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup,
  Divider,
  useTheme,
  Button,
  Box,
} from "@mui/material";


const Filters = ({ onApplyFilters }) => {
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [inStock, setInStock] = useState(false);
  const [onSale, setOnSale] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const categories = [
    { id: 1, name: "Vegetables" },
    { id: 2, name: "Seasonal Vegetables" },
    { id: 3, name: "Fruits" },
  ];


const handleCategoryChange = (categoryId) => {
  if (selectedCategories.includes(categoryId)) {
    setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
  } else {
    setSelectedCategories([...selectedCategories, categoryId]);
  }
};
  


  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };


  const applyFilters = () => {
    onApplyFilters({
      priceRange,
      inStock,
      onSale,
      selectedCategories,
    });
  };

  return (
    <Box sx={{p:2}}>
      <Typography variant="h7" fontWeight="bold">Filter by Price</Typography>
      <Slider
        value={priceRange}
        onChange={handlePriceChange}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={5}
      />
      <Typography>
        Price: ₹{priceRange[0]} — ₹{priceRange[1]}
      </Typography>
      <Divider sx={{my:2}}/>
      <Typography variant="h7" fontWeight="bold" style={{ marginTop: "16px" }}>
        Product Status
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={inStock}
              onChange={(e) => setInStock(e.target.checked)}
            />
          }
          label="In Stock"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onSale}
              onChange={(e) => setOnSale(e.target.checked)}
            />
          }
          label="On Sale"
        />
      </FormGroup>

      <Divider sx={{my:2}}/>

      <Typography variant="h7" fontWeight="bold" style={{ marginTop: "16px" }}>
  Categories
</Typography>
<FormGroup>
  {categories.map((category) => (
    <FormControlLabel
      key={category.id}
      control={
        <Checkbox
          checked={selectedCategories.includes(category.id)}
          onChange={() => handleCategoryChange(category.id)}
        />
      }
      label={category.name}
    />
  ))}
</FormGroup>

      <Button
      variant="contained"
      color="primary"
      size="small"
        onClick={applyFilters}
        sx={{
          mt: 2,  
          color:"white"     
        }}
      >
        Apply Filters
      </Button>
    </Box>
  );
};

export default Filters;


