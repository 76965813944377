import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Grid container spacing={4} sx={{ minHeight: "80vh", alignItems: "center" }}>
                {/* Left Section: Text */}
                <Grid item xs={12} md={6}>
                    <Box textAlign={{ xs: "center", md: "left" }}>
                        <Typography variant="h2" fontWeight="bold" gutterBottom>
                            404 - Page Not Found
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            The page you're looking for doesn't exist or has been moved.
                        </Typography>
                        <Button 
                            onClick={() => navigate("/")} 
                            variant="contained" 
                            sx={{ color: "white", mt: 2 }}
                        >
                            Go to Homepage
                        </Button>
                    </Box>
                </Grid>

                {/* Right Section: Image */}
                <Grid item xs={12} md={6}>
                    <Box 
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img loading='lazy'
                            style={{ maxWidth: "100%", maxHeight: "400px", objectFit: "contain" }} 
                            src="/page_not_found.webp" 
                            alt="Page not found illustration" 
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ErrorPage;
