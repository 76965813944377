import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    typography: {
        fontFamily: 'Quicksand, serif'
    },
  palette: {
    mode: 'light',
    primary: {
      main: '#60be74',
    },
 
    secondary: {
      main: '#2e7d32',
    },
  },
});

export const darkTheme = createTheme({
    typography: {
      fontFamily: ' "Roboto Condensed", sans-serif'
    },
  palette: {
    mode: 'dark',
    primary: {
      // main: '#90caf9',   //blue color 
      //  main: '#1e1e1e',  
      //  main:'#a7a6ba'   
      main:'#778899'
    },

   
    secondary: {
      main: '#232323',
    },
  },
});
