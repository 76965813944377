import axios from "axios";
import authService from "./authService";
import api from "../apiCollection";

const getAuthToken = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  console.log("AuthToken:", token);
  return token;
};

const getAddress = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.address.getAddress}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getAddress response:", response);
    return response.data;
  } catch (error) {
    console.error("Error getting address:", error);
    throw error;
  }
};

const addAddress = async (
  user_name,
  building_name_area,
  pincode,
  city,
  state,
  country,
  landmark,
  phone_number,
  location_type,
  is_default
) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("user_name", user_name);
    formData.append("building_name_area", building_name_area);
    formData.append("pincode", pincode);
    formData.append("is_default", is_default ? 1 : 0);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("landmark", landmark);
    formData.append("phone_number", phone_number);
    formData.append("location_type",location_type)
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await axios.post(`${api.address.addAddress}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("addAddress response:", response);
    return response.data;
  } catch (error) {
    console.error("Error adding address:", error);
    throw error;
  }
};

const updateAddress = async (
  id,
  user_name,
  building_name_area,
  pincode,
  city,
  state,
  country,
  landmark,
  phone_number,
  location_type,
  is_default,
) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("user_name", user_name);
    formData.append("building_name_area", building_name_area);
    formData.append("pincode", pincode);
    formData.append("is_default", is_default ? 1 : 0);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("landmark", landmark);
    formData.append("phone_number", phone_number);
    formData.append("location_type", location_type);

    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await axios.post(
      `${api.address.updateAddress}/${id}?_method=PUT`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("updateAddress response:", response);
    return response.data;
  } catch (error) {
    console.error("Error updating address:", error);
    throw error;
  }
};

const deleteAddress = async (id) => {
  try {
    const token = getAuthToken();
    await axios.delete(`${api.address.deleteAddress}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("deleteAddress response: Address deleted");
    return id;
  } catch (error) {
    console.error("Error deleting address:", error);
    throw error;
  }
};

export default {
  getAddress,
  addAddress,
  updateAddress,
  deleteAddress,
};
