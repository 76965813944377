import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppRoutes from './routes';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useThemeContext } from './context/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import { generateToken, messaging } from './components/notifications/firebase';
import { onMessage } from 'firebase/messaging';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const App = () => {
    const { theme } = useThemeContext();
    const { pathname } = useLocation();

    // Scroll to top and track pageview on route change
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(pathname);
    }, [pathname]);

    // Firebase notifications
    useEffect(() => {
        generateToken();
        onMessage(messaging, (payload) => {
            console.log(payload);
            toast.success(payload.notification.body);
        });
    }, []);

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <MuiThemeProvider theme={theme}>
                <ToastContainer
                    stacked
                    progressStyle={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                    theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
                    autoClose={800}
                />
                <CssBaseline />
                <AppRoutes />
            </MuiThemeProvider>
        </GoogleOAuthProvider>
    );
};

export default App;
