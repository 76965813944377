import React from 'react'
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button"
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import OrderDetails from './OrderDetails'
import { IconButton } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function OrderItem({ order, onViewDetails, onClose, showDetails }) {

    console.log(order);

    const dateString = order.address.created_at;
    const date = new Date(dateString);

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true, hourCycle: 'h12' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    console.log(formattedDate);

    return (
        <Box boxShadow={2} borderRadius={2} p={2} width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"} >
            {showDetails ? (
                // Order details view
                <OrderDetails order={order} onClose={onClose} dateTime={formattedDateTime} />
            ) : (
                // Order summary view
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            {
                                order.order_item.map(item => (
                                    <Box key={item.id} sx={{ width: 50, height: 50, overflow: "hidden", borderRadius: 2 }}>
                                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={item.product.image} />
                                    </Box>

                                ))
                            }
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant='body2' fontWeight={"bold"}>Order {order.payment.state ? order.payment.state : "placed"}</Typography>
                            <Typography variant='body2' color={"GrayText"}>Placed at {formattedDateTime}</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: 2 }}>
                        <Typography color={"primary"} fontWeight={500} variant='h6'>₹{order.grand_total}</Typography>

                        <IconButton onClick={onViewDetails} sx={{ boxShadow: 3 }} ><ArrowForwardIosIcon sx={{ fontSize: "0.8rem", color: "gray" }} /></IconButton>
                    </Box>



                </Box>
            )}
        </Box>
    );
}
