import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents, useMap } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Icon, divIcon, point } from 'leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import Button from '@mui/material/Button';


export default function Map() {
    const [userLocation, setUserLocation] = useState(null);
    // const [selectedLocation, setSelectedLocation] = useState(null);
    const [address, setAddress] = useState('');

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    console.log("currect lat/long :",latitude,longitude)
                    setUserLocation({ latitude, longitude });
                },
                error => {
                    console.error("Error getting user location: ", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser");
        }
    }

    // const getAddress = async (lat, lon) => {
    //     try {
    //         const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`);
    //         const data = await response.json();
    //         console.log(data);
    //         setAddress(data.display_name);
    //     } catch (error) {
    //         console.error("Error fetching address: ", error);
    //     }
    // }

    // const MapEvents = () => {
    //     useMapEvents({
    //         click(e) {
    //             const { lat, lng } = e.latlng;
    //             setSelectedLocation({ latitude: lat, longitude: lng });
    //             getAddress(lat, lng);
    //         },
    //     });
    //     return null;
    // }

    useEffect(() => {
        getUserLocation();
    }, []);

    const customIconHome = new Icon({
        iconUrl: require("../assets/home-address.png"),
        iconSize: [38, 38],
    });
    // const customIconLocation = new Icon({
    //     iconUrl: require("../assets/location-icon.png"),
    //     iconSize: [38,38]
    // })

    const createCustomClusterIcon = (cluster) => {
        return new divIcon({
            html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
            className: "custom-marker-cluster",
            iconSize: point(33, 33, true),
        });
    }

    const RecenterButton = ({ userLocation }) => {
        const map = useMap();

        const handleRecenter = () => {
            if (userLocation) {
                console.log("Recentering map to: ", userLocation);  
                map.setView([userLocation.latitude, userLocation.longitude], 15);
            }
        }

        return (
            <Button
                onClick={handleRecenter}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 1000,
                    padding: '10px',
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Recenter
            </Button>
        );
    }

    return (
        <>
            {userLocation ? (
                <MapContainer center={[userLocation.latitude, userLocation.longitude]} zoom={15} style={{ height: "100%", width: "100%",minHeight:"400px",position:"relative" }}>
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup chunkedLoading iconCreateFunction={createCustomClusterIcon}>
                        <Marker position={[userLocation.latitude, userLocation.longitude]} icon={customIconHome}>
                            <Popup><h2>This is your current location</h2></Popup>
                        </Marker>
                        {/* {selectedLocation && (
                            <Marker position={[selectedLocation.latitude, selectedLocation.longitude]} icon={customIconLocation}>
                                <Popup>
                                    <h2>Selected Location</h2>
                                    <p>{address}</p>
                                </Popup>
                            </Marker>
                        )} */}
                    </MarkerClusterGroup>
                    {/* <MapEvents /> */}
                    <RecenterButton userLocation={userLocation} /> 
                </MapContainer>
            ) : (
                <p>Loading user location...</p>
            )}
        </>
    );
}
