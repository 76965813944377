import { Box, Divider, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CheckCircle } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export default function FreeDelivery({ cartTotal, onChange = () => {}  }) {
    const deliveryCharge = 50;
    const freeShippingThreshold = 500;
    const progress = Math.min((cartTotal / freeShippingThreshold) * 100, 100);

    useEffect(() => {
        if (cartTotal > freeShippingThreshold) {
          onChange(true);
        } else {
          onChange(false);
        }
      }, [cartTotal, onChange]);
    return (
        <>
            <Divider variant='fullWidth' sx={{ borderStyle: "dashed" }} />
            {/* free delivery */}
            <Box sx={{ display: "flex", gap: 2, my: 2, alignItems: "center" }}>
                <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress variant="determinate" value={progress} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {progress === 100 ? (
                            <CheckCircle sx={{ color: 'green' }} /> 
                        ) : (
                            <Typography
                                variant="caption"
                                component="div"
                                sx={{ color: 'text.secondary' }}
                            >
                                {`${parseInt(progress)}%`}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box>
                    {progress === 100 ? (
                        <Typography variant='body2'>You <span style={{ fontWeight: "bold", color: "green", fontSize: "1rem" }}>
                            saved ₹{deliveryCharge}
                        </span>{' '} on delivery fee!</Typography> 
                    ) : (
                        <Typography variant='body2'>
                            Shop for ₹{freeShippingThreshold - cartTotal} more to{' '}
                            <span style={{ fontWeight: "bold", color: "green", fontSize: "1rem" }}>
                                save ₹{deliveryCharge}
                            </span>{' '}
                            on delivery fee
                        </Typography>
                    )}
                </Box>
            </Box>

            <Divider variant='fullWidth' sx={{ borderStyle: "dashed" }} /></>
    )
}
