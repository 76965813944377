import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToLocalCart,
  addItemToServerCart,
  fetchLocalCart,
  fetchServerCart,
} from "../../redux/slices/cartSlice";
import { Button, Box } from "@mui/material";
import QuantitySelector from "../cart/QuantitySelector";

const AddToCartButton = ({ product }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const cartItems = isAuthenticated ? serverCart.products : localCart.products;

  const isProductInCart = cartItems.some(
    (item) => item.product.id === product?.id
  );

  const handleAddItem = async () => {
    setIsLoading(true);
    try {
      if (isAuthenticated) {
        await dispatch(addItemToServerCart(product)).unwrap();
        dispatch(fetchServerCart());
      } else {
        dispatch(addItemToLocalCart(product));
        dispatch(fetchLocalCart());
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      {isProductInCart ? (
        <QuantitySelector
          item={cartItems.find((item) => item.product.id === product.id)}
        />
      ) : (
        <Button
          onClick={handleAddItem}
          disabled={isLoading || !product.is_in_stock}
          variant="contained"
          color="primary"
          size="small"
          sx={{ width: "100%", color: "white" }}
        >
          {product.is_in_stock ? "Add to Cart" : "Out Of Stock"}
        </Button>
      )}
    </Box>
  );
};

export default AddToCartButton;
