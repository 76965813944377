import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, TableRow, IconButton, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import QuantitySelector from './QuantitySelector';
import { removeItemFromLocalCart, removeItemFromServerCart } from '../../redux/slices/cartSlice';

export default function CartTableItem({ item }) {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector(state => state.auth);

    const handleRemove = () => {
        if (isAuthenticated) {
            dispatch(removeItemFromServerCart({ productId: item.product.id }));
        } else {
            dispatch(removeItemFromLocalCart({ productId: item.product.id }));
        }
    };

    const isOfferApplied = Boolean(item.product.discounted_price || item.product.offer_price);

    return (
        <>
            <TableRow>
                {/* Product Information */}
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img
                            src={item.product.image}
                            alt={item.product.name}
                            width={50}
                            height={50}
                            style={{ borderRadius: 4, objectFit: "cover" }}
                        />
                        <Box>
                            <Typography variant="body1" fontWeight={500}>
                                {item.product.name}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        textDecoration: item.product.discounted_price ? 'line-through' : 'none',
                                        color: item.product.discounted_price ? 'gray' : 'inherit',
                                    }}
                                >
                                    ₹ {parseInt(item.product.price)}
                                </Typography>
                                {item.product.discounted_price && (
                                    <Typography variant="body2" fontWeight={"bold"} color={"green"}>₹ {Math.round(item.product.offer_price) || parseInt(item.product.discounted_price)}</Typography>
                                )}
                            </Box>
                            {/* Offer Indicator */}
                            {isOfferApplied && (
                                <Typography
                                    variant="caption"
                                    color="primary"
                                    sx={{ mt: 1, fontWeight:"bold", color:"green" }}
                                >
                                    Offer Applied!
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </TableCell>

                {/* Quantity Selector */}
                <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <QuantitySelector item={item} />
                    </Box>
                </TableCell>

                {/* Total Price */}
                <TableCell align="center">
                    <Typography variant="body2">
                        ₹{Math.round(item.product.offer_price || item.product.discounted_price || item.product.price) * item.quantity}
                    </Typography>
                </TableCell>

                {/* Remove Button */}
                <TableCell align="center">
                    <IconButton onClick={handleRemove} aria-label="delete">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}
