import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, CircularProgress,  FormControl, ToggleButtonGroup, ToggleButton } from '@mui/material';
import axios from 'axios';
import api from '../../apiCollection';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
    createAddress,
    fetchAddress,
    modifyAddress,
    setDefaultAddress,
} from '../../redux/slices/addressSlice';

const AddressFormFields = ({ editingAddress, onClose }) => {
    const [address, setAddress] = useState({
        buildingNameArea: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        name: '',
        phone: '',
        pincode: '',
        locationType: 'home',
    });
    const [loading, setLoading] = useState(false);
    const { addresses: reduxAddress } = useSelector((state) => state.address);
    const dispatch = useDispatch();

    const [pincodeError, setPincodeError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    useEffect(() => {
        if (editingAddress) {
            setAddress({
                buildingNameArea: editingAddress.building_name_area || '',
                landmark: editingAddress.landmark || '',
                city: editingAddress.city || '',
                state: editingAddress.state || '',
                country: editingAddress.country || '',
                name: editingAddress.user_name || '',
                phone: editingAddress.phone_number || '',
                pincode: editingAddress.pincode || '',
                locationType: editingAddress.location_type || 'home',
            });
        }
    }, [editingAddress]);

    useEffect(() => {
        const debouncedValidatePhoneNumber = debounce(validatePhoneNumber, 1000);
        debouncedValidatePhoneNumber(address.phone);

        return () => debouncedValidatePhoneNumber.cancel();
    }, [address.phone]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'pincode') {
            setPincodeError('');
        }
        if (name === 'phone') {
            setPhoneError('');
        }

        if (name === 'pincode' && value.length > 6) {
            return;
        }

        setAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'pincode' && value.length === 6) {
            handlePincodeChange(value);
        }
    };

    const handlePincodeChange = async (pincode) => {
        try {
            const response = await axios.get(`${api.pincodeApi}/${pincode}`);
            if (response.data.Status === 'Success') {
                const postOffice = response.data.PostOffice[0];
                setAddress((prevState) => ({
                    ...prevState,
                    city: postOffice.District,
                    state: postOffice.State,
                    country: postOffice.Country,
                }));
            } else {
                setPincodeError('Invalid Pincode');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
            setPincodeError('Error fetching location data');
        }
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        if (phone && !phoneRegex.test(phone)) {
            setPhoneError('Phone number invalid');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        try {
            if (editingAddress) {
                const id = editingAddress.id;
                await dispatch(modifyAddress({ id, address }));
            } else {
                const resultAction = await dispatch(createAddress(address));
                const createdAddress = unwrapResult(resultAction);
    
                // Dispatch only for the first address
                if (createdAddress && createdAddress.id && reduxAddress.length === 0) {
                    await dispatch(setDefaultAddress(createdAddress.id));
                }
            }
    
            await dispatch(fetchAddress());
        } catch (error) {
            console.error('Error during address submission:', error);
        } finally {
            setLoading(false);
            onClose();
        }
    };
    

    return (
        <form onSubmit={handleSubmit}>
             <FormControl fullWidth margin="dense" size="small">
                <ToggleButtonGroup
                color='primary'
                    value={address.locationType}
                    exclusive
                    onChange={(event, newValue) => {
                        if (newValue !== null) {
                            setAddress((prevState) => ({
                                ...prevState,
                                locationType: newValue,
                            }));
                        }
                    }}
                    size="small"
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <ToggleButton value="home" sx={{ flex: 1, textTransform: 'none' }}>
                        Home
                    </ToggleButton>
                    <ToggleButton value="office" sx={{ flex: 1, textTransform: 'none' }}>
                        Office
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormControl>
            <TextField
                margin="dense"
                size="small"
                required
                label="Flat / House no / Building name"
                fullWidth
                name="buildingNameArea"
                value={address.buildingNameArea}
                onChange={handleChange}
            />

            <TextField
                margin="dense"
                size="small"
                required
                label="Nearby landmark"
                fullWidth
                name="landmark"
                value={address.landmark}
                onChange={handleChange}
            />
            <TextField
                margin="dense"
                size="small"
                required
                type="number"
                label="Pincode"
                fullWidth
                name="pincode"
                value={address.pincode}
                onChange={handleChange}
                inputProps={{ maxLength: 6 }}
                error={Boolean(pincodeError)}
                helperText={pincodeError}
            />

            <TextField
                margin="dense"
                size="small"
                required
                label="City"
                fullWidth
                name="city"
                value={address.city}
                onChange={handleChange}
            />

            <TextField
                margin="dense"
                size="small"
                required
                label="State"
                fullWidth
                name="state"
                value={address.state}
                onChange={handleChange}
            />

            <TextField
                margin="dense"
                size="small"
                label="Country"
                required
                fullWidth
                name="country"
                value={address.country}
                onChange={handleChange}
            />

           

            <TextField
                margin="dense"
                size="small"
                required
                label="Your name"
                fullWidth
                name="name"
                value={address.name}
                onChange={handleChange}
            />

            <TextField
                margin="dense"
                size="small"
                required
                label="Your phone number"
                fullWidth
                type="tel"
                name="phone"
                value={address.phone}
                onChange={handleChange}
                error={Boolean(phoneError)}
                helperText={phoneError}
            />

            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Button onClick={onClose} variant="outlined" size="small" fullWidth>
                    Back
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{ color: 'white' }}
                >
                    {loading ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                    ) : editingAddress ? (
                        'Update Address'
                    ) : (
                        'Save Address'
                    )}
                </Button>
            </Box>
        </form>
    );
};

export default AddressFormFields;
