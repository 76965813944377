import React from 'react';
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom'
import EmptyCart from '../EmptyCart';
import { CircularProgress, Divider } from '@mui/material';
import CartList from './CartList';
import FreeDelivery from './FreeDelivery';


export default function CartDrawer({ toggleDrawer, drawerOpen }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const cart = isAuthenticated ? serverCart : localCart;
  console.log("cart", cart);
  

  const theme = useTheme();

  const isEmpty = cart.products.length === 0;
 




  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
      <Box sx={{ width: "400px", height: "100vh", position: "relative", overflow: "hidden", display: "flex", flexDirection: "column", p: 2 }}>
        {cart.status === "loading" ? (
          <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          isEmpty ? (
            <EmptyCart onClose={toggleDrawer} />
          ) : (
            <>
              <Typography sx={{ fontWeight: 800 }} variant="h6" gutterBottom>My Cart</Typography>
              <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>

              <FreeDelivery  cartTotal={cart.total} />

             
              <Box className="scroll" sx={{
                mb: 8, pr: 2,
                overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.primary.main
                }
              }}>

                <CartList onClose={toggleDrawer} />
                
              </Box>

              <Box sx={{ width: "100%", textAlign: "center", boxShadow: 20, bgcolor: "white", position: "absolute", bottom: 0, left: 0, p: 1 }}>
          <Typography variant='h6' gutterBottom>Subtotal: <span style={{ color: "green", fontWeight: "bold" }}>₹{cart.total}</span></Typography>
            <Link to={"/cart"}>
          <Button onClick={toggleDrawer} variant='contained' fullWidth sx={{ color: "white" }}>
            Checkout
          </Button>
            </Link>
        </Box>

            </>
          )
        )}
   
      </Box>

    </Drawer>
  );
}
