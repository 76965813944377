import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Chip,
} from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import DiscountLabel from "./DiscountLabel";
import { useNavigate } from "react-router-dom";
import AddToCartButton from "./AddToCartButton";
import OfferDetails from "./OfferDetails";

const StyledProductCard = styled(Card)(({ theme, listview }) => ({
  maxWidth: listview ? "100%" : 300,
  boxShadow: "none",
  position: "relative",
  height: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: listview ? "row" : "column",
  alignItems: listview ? "flex-start" : "stretch",
  gap: listview ? theme.spacing(2) : 0,
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s",
  "&:hover .productImg": {
    transform: "scale(1.05)",
  },
}));

const StyledProductImage = styled(CardMedia)(({ theme, listview }) => ({
  width: listview ? 200 : "100%",
  height: listview ? "100%" : 150,
  borderRadius: 5,
  transition: "transform 0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    height: listview ? "100%" : 100,
    width: listview ? 150 : "100%",
  },
}));

const ProductCard = ({ product, listview }) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleImageClick = () => {
    navigate(`/product/${product.slug}`);
  };

  const originalPrice = Math.round(Number(product?.price) || 0);
  const discountedPrice = Math.round(Number(product?.discounted_price) || 0);

  // Extract offer details safely
  const offer = product?.offer_details || null;
  let discountPercentage = 0;

  if (offer) {
    if (offer.discount_type === "FLAT" && offer.discount_value) {
      discountPercentage = Math.round(
        (Number(offer.discount_value) / discountedPrice) * 100
      );
    } else if (offer.discount_type === "%" && offer.discount_value) {
      discountPercentage = Math.round(Number(offer.discount_value));
    } else {
      discountPercentage = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
    }
  }

  return (
    <StyledProductCard listview={listview}>
      <Box
        sx={{
          position: "relative",
          height: listview ? "100%" : "auto",
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        <StyledProductImage
          onClick={handleImageClick}
          listview={listview}
          className="productImg"
          component="img"
          image={product?.image || `https://via.placeholder.com/150`}
          alt={product?.name || "Product"}
        />
        {offer && <DiscountLabel discount={discountPercentage} />}
        {offer && (
          <Chip
            onClick={() => setDetailOpen(true)}
            label={offer.name || "Special Offer"}
            color="secondary"
            size="small"
            sx={{
              position: "absolute",
              bottom: 10,
              left: "50%",
              transform: "translateX(-50%)",
              background: "linear-gradient(to right, rgb(236, 35, 52), rgb(251, 180, 82))",
              boxShadow: 2,
              color: "white",
              zIndex: 2,
              transition: "transform 0.3s ease, background 0.3s ease",
              "&:hover": {
                transform: "translateX(-50%) scale(1.1)",
              },
            }}
          />
        )}
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&:last-child": {
            paddingBottom: 0,
          },
          flex: 1,
          p: listview ? 1 : 0,
        }}
      >
        <Box>
          <Typography
            sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
            onClick={handleImageClick}
            gutterBottom
            variant="h7"
            fontWeight={500}
          >
            {product?.name}
          </Typography>
          <Typography sx={{ color: "GrayText" }} variant="body2">
            {product.item_quantity} {product.stock_unit}
          </Typography>
          {listview && (
            <Typography variant="body2" color="text.secondary">
              {product.description}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mt: 1,
            }}
          >
            {offer && (
              <Typography
                variant={isSmallScreen ? "body2" : "body1"}
                sx={{
                  color: "white",
                  background: "linear-gradient(90deg, rgba(236,35,52,1) 0%, rgba(251,180,82,1) 100%)",
                  paddingX: 1,
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                ₹{Math.round(product.offer_price || 0)}
              </Typography>
            )}
            {product?.discounted_price && (
              <Typography
                variant={isSmallScreen ? "body2" : "body1"}
                color="secondary"
                fontWeight={"bold"}
                sx={{ textDecoration: offer ? "line-through" : "none" }}
              >
                ₹{product.discounted_price}
              </Typography>
            )}
            {(product?.discounted_price || offer) && (
              <Typography
                variant={isSmallScreen ? "body2" : "body1"}
                sx={{
                  textDecoration: "line-through",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                ₹{product?.price}
              </Typography>
            )}
          </Box>
        </Box>
        {offer && (
          <OfferDetails
            offer={offer}
            detailOpen={detailOpen}
            handleClose={() => setDetailOpen(false)}
          />
        )}
        <Box>
          <AddToCartButton product={product} />
        </Box>
      </CardContent>
    </StyledProductCard>
  );
};

export default ProductCard;
