// addressSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import addressService from "../../services/addressService";

export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async (_, thunkAPI) => {
    try {
      const response = await addressService.getAddress();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createAddress = createAsyncThunk(
  "address/createAddress",
  async (addressData, thunkAPI) => {
    try {

      const {
        name,
        buildingNameArea,
        pincode,
        city,
        state,
        country,
        landmark,
        phone,
        locationType
      } = addressData;

      const response = await addressService.addAddress(
        name,
        buildingNameArea,
        pincode,
        city,
        state,
        country,
        landmark,
        phone,
        locationType
      );
      console.log("Address created response",response.data)
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const modifyAddress = createAsyncThunk(
  "address/modifyAddress",
  async ({ id, address }, thunkAPI) => {
    try {
      console.log(id, address);
      const {
        name,
       buildingNameArea,
        pincode,
        city,
        state,
        country,
        landmark,
        phone,
        locationType
      } = address;
      const response = await addressService.updateAddress(
        id,
        name,
        buildingNameArea,
        pincode,
        city,
        state,
        country,
        landmark,
        phone,
        locationType
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeAddress = createAsyncThunk(
  "address/removeAddress",
  async (id, thunkAPI) => {
    try {
      await addressService.deleteAddress(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setDefaultAddress = createAsyncThunk(
  "address/setDefaultAddress",
  async (id, thunkAPI) => {
    try {
      const State = thunkAPI.getState();
      const addresses = State.address.addresses;
      

      // Update all addresses to set is_default to false, except the one with the given id
    
      const updatePromises = addresses.map((address) => {
        const is_default = address.id === id ? 1 : 0;
        const { 
          user_name,
          building_name_area,
          pincode,
          city,
          state,
          country,
          landmark,
          location_type,
          phone_number,
        } = address;
        return addressService.updateAddress(address.id, 
          user_name,
          building_name_area,
          pincode,
          city,
          state,
          country,
          landmark,
          phone_number,
          location_type,
          is_default
        );
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      // Fetch updated addresses
      const response = await addressService.getAddress();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  addresses: [],
  status: "idle",
  error: null,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    resetAddressState: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses = action.payload;
      })
      .addCase(fetchAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error(`Failed to fetch addresses: ${action.payload}`, {
          position: "top-center",
        });
      })
      .addCase(createAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses.push(action.payload);
        toast.success("Address created successfully!", {
          position: "top-center",
        });
      })
      .addCase(createAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error("Failed to create address", {
          position: "top-center",
        });
      })
      .addCase(modifyAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(modifyAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.addresses.findIndex(
          (address) => address.id === action.payload.id
        );
        if (index !== -1) {
          state.addresses[index] = action.payload;
          toast.success("Address updated successfully!", {
            position: "top-center",
          });
        }
      })
      .addCase(modifyAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error(`Failed to update address: ${action.payload}`, {
          position: "top-center",
        });
      })
      .addCase(removeAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses = state.addresses.filter(
          (address) => address.id !== action.payload
        );
        toast.success("Address removed successfully!", {
          position: "top-center",
        });
      })
      .addCase(removeAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error(`Failed to remove address: ${action.payload}`, {
          position: "top-center",
        });
      })
      .addCase(setDefaultAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setDefaultAddress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses = action.payload;
      })
      .addCase(setDefaultAddress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error(`Failed to set default address: ${action.payload}`, {
          position: "top-center",
        });
      });
  },
});

export const { resetAddressState } = addressSlice.actions;
export default addressSlice.reducer;
