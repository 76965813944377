import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Box, Typography, Button, keyframes, useTheme, useMediaQuery, Grid } from "@mui/material";
import { Link } from "react-router-dom";

// Keyframe animations for text
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroSlider = () => {
  const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

  const slides = [
    {
      id: 1,
      image: `${baseCloudinaryURL}/v1737561583/vegingo/vegingo.com_mcbtvm.jpg`,
      title: "Fresh Vegetables & Fruits",
      description: "Delivered directly to your doorstep.",
      buttonText: "Shop Now",
      buttonLink: "/shop",
    },
    {
      id: 2,
      image: `${baseCloudinaryURL}/v1737561580/vegingo/vegingo.com_2_tpvppp.jpg`,
      title: "Bulk Discounts Available",
      description: "Special prices for vendors.",
      buttonText: "Learn More",
      buttonLink: "/bulk-orders",
    },
    {
      id: 3,
      image: `${baseCloudinaryURL}/v1737561578/vegingo/vegingo.com_1_trrzw3.jpg`,
      title: "Organic & Fresh Produce",
      description: "Healthy living starts here.",
      buttonText: "Explore",
      buttonLink: "/organic",
    },
    {
      id: 4,
      image: `${baseCloudinaryURL}/v1737561939/vegingo/vegingo.com_3_biwcyl.jpg`,
      title: "Exclusive Deals",
      description: "Don't miss out on special offers.",
      buttonText: "Discover",
      buttonLink: "/deals",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container>
        {/* Slider */}
        <Grid item xs={12} md={8}>
          <Swiper
            modules={[Navigation, Autoplay, EffectFade]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
            autoplay={{ delay: 5000 }}
            loop
            effect="fade"
            fadeEffect={{ crossFade: true }}
            onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={slide.id}>
                <Box
                  sx={{
                    backgroundImage: `url(${slide.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: isSmallScreen ? "300px" : "600px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      borderRadius: 2,
                      padding: isSmallScreen ? 2 : 4,
                      opacity: activeSlide === index ? 1 : 0,
                      transition: "opacity 0.5s ease-in-out",
                      animation: activeSlide === index ? `${fadeIn} 1s ease-in-out` : "none",
                      backdropFilter: "blur(10px)",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Typography
                      variant={isSmallScreen ? "h5" : "h3"}
                      sx={{ mb: 2, fontWeight: "bold" }}
                    >
                      {slide.title}
                    </Typography>
                    <Typography
                      variant={isSmallScreen ? "body2" : "h6"}
                      sx={{ mb: 3 }}
                    >
                      {slide.description}
                    </Typography>
                    <Link to={slide.buttonLink}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          background: "linear-gradient(to right, #4CAF50, #81C784)",
                          color: "white",
                          px: 4,
                          py: 1.5,
                          fontWeight: "bold",
                          "&:hover": {
                            background:
                              "linear-gradient(to right, #388E3C, #66BB6A)",
                          },
                        }}
                      >
                        {slide.buttonText}
                      </Button>
                    </Link>
                  </Box>

                </Box>
              </SwiperSlide>
            ))}

            {/* Custom Navigation */}
            <Box
              className="custom-prev"
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "10px",
                zIndex: 10,
                fontSize: "2rem",
                cursor: "pointer",
                opacity: 0.8,
                "&:hover": { opacity: 1 },
              }}
            >
              &#8592;
            </Box>
            <Box
              className="custom-next"
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                right: "10px",
                zIndex: 10,
                cursor: "pointer",
                fontSize: "2rem",
                opacity: 0.8,
                "&:hover": { opacity: 1 },
              }}
            >
              &#8594;
            </Box>
          </Swiper>
        </Grid>

        {/* Second Grid for Images */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "row" : "column",
              justifyContent: "space-between",
              height: isSmallScreen ? "auto" : "600px",
              gap: isSmallScreen ? 1 : 0,
            }}
          >
            <Box
              component={Link}
              to="/shop/vegetable"
              sx={{
                backgroundImage: `url(${baseCloudinaryURL}/v1732198785/vegingo/Order_Now_2_zvaxcm.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: isSmallScreen ? "150px" : "50%",
                width: isSmallScreen ? "50%" : "100%",
              }}
            />
            <Box
              component={Link}
              to="/shop/fruit"
              sx={{
                backgroundImage: `url(${baseCloudinaryURL}/v1732198361/vegingo/Order_Now_1_pvrptf.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: isSmallScreen ? "150px" : "50%",
                width: isSmallScreen ? "50%" : "100%",
              }}
            />
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default HeroSlider;
