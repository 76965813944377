import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
    Email,
    Phone,
    LocationOn,
    Facebook,
    WhatsApp,
    Instagram,
    LinkedIn,
} from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import api from '../../apiCollection';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const bgImageURL = `${baseCloudinaryURL}/w_1920,h_1080,c_fill,q_auto,f_auto/v1722407343/vegingo/Footer_smgyqe.jpg`;

const Footer = () => {
    const [email, setEmail] = useState('');
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );

    const handleSubscribe = async () => {
        try {
            const response = await axios.post(api.newsletter, { email });

            if (response.status === 201) {
                toast.success(response.data.message, {
                    position: 'top-center',
                });
            } else if (
                response.status === 200 &&
                response.data.message === 'You are already subscribed!'
            ) {
                toast.info('You are already a subscriber.', {
                    position: 'top-center',
                });
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            toast.error('Subscription failed. Please try again later.', {
                position: 'top-center',
            });
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                color: 'white',
                bgcolor: "black"
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 2,
                }}
            >
                <Container
                    maxWidth="lg"
                    sx={{
                        position: 'relative',
                        zIndex: 2,
                        color: 'white',
                        py: { xs: 10, md: 4 },
                    }}
                >
                    <Grid container>
                        <Grid item md={2} xs={4}>
                            <img src="/Vegingo-logo-circular.png" width={200} />
                        </Grid>

                        <Grid
                            item
                            md={7}
                            xs={8}
                            sx={{ textAlign: 'center', p: 2 }}
                        >
                            <Typography
                                gutterBottom
                                variant={isSmallScreen ? 'h6' : 'h4'}
                            >
                                Subscribe Now
                            </Typography>
                            <TextField
                                label="Email"
                                fullWidth
                                size={isSmallScreen ? 'small' : 'medium'}
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                edge="end"
                                                color="primary"
                                                onClick={handleSubscribe}
                                                sx={{
                                                    bgcolor:
                                                        theme.palette.primary
                                                            .main,
                                                    mr: 0.1,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.dark,
                                                    },
                                                }}
                                            >
                                                <SendIcon
                                                    sx={{ color: 'white' }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                        '& input': {
                                            color: 'white',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',
                                        '&.Mui-focused': {
                                            color: 'white',
                                        },
                                    },
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Link
                                    target="_blank"
                                    to="https://www.facebook.com/profile.php?id=61564613017498&mibextid=ZbWKwL"
                                >
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <Facebook
                                            sx={{ color: 'white' }}
                                            fontSize="medium"
                                        />
                                    </IconButton>
                                </Link>

                                <Link
                                    target="_blank"
                                    to="https://api.whatsapp.com/send?phone=918765126842"
                                >
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <WhatsApp
                                            sx={{ color: 'white' }}
                                            fontSize="medium"
                                        />
                                    </IconButton>
                                </Link>

                                <Link
                                    target="_blank"
                                    to="https://www.instagram.com/vegingo.ig?igsh=MWR1c29jMjJoMGNzNw=="
                                >
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <Instagram
                                            sx={{ color: 'white' }}
                                            fontSize="medium"
                                        />
                                    </IconButton>
                                </Link>

                                <Link
                                    target="_blank"
                                    to="https://www.linkedin.com/company/vegingo"
                                >
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <LinkedIn
                                            sx={{ color: 'white' }}
                                            fontSize="medium"
                                        />
                                    </IconButton>
                                </Link>

                                <Link target="_blank" to="/install">
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <DownloadIcon
                                            sx={{ color: 'white' }}
                                            fontSize="medium"
                                        />
                                    </IconButton>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid sx={{ mt: 2 }} container columnSpacing={4} rowGap={2}>
                        <Grid item xs={6} md={4}>
                            <Typography
                                gutterBottom
                                variant="h6"
                                sx={{ textDecoration: 'underline' }}
                            >
                                Why People Like Us ?
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                VeginGo delivers fresh, quality veggies and
                                fruits, ensuring convenience, transparency,
                                eco-friendly practices, and supporting local
                                farmers for a healthier community.
                            </Typography>
                            <Link to="/about-us">
                               <Typography sx={{textDecoration:"underline"}}variant='body2'>see more...</Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography
                                variant="h6"
                                sx={{ textDecoration: 'underline' }}
                            >
                                Information
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Link to="/about-us">
                                                <Typography sx={{ "&:hover": { textDecoration: "underline" } }} variant="body2">
                                                    About Us
                                                </Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Link to="/faq">
                                                <Typography sx={{ "&:hover": { textDecoration: "underline" } }}  variant="body2">
                                                    FAQ
                                                </Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Link to="/privacy-policy">
                                                <Typography sx={{ "&:hover": { textDecoration: "underline" } }} variant="body2">
                                                    Privacy Policy
                                                </Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Link to="/terms-and-conditions">
                                                <Typography sx={{ "&:hover": { textDecoration: "underline" } }} variant="body2">
                                                    Terms & Conditions
                                                </Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Link to="/shipping-and-return-policy">

                                                <Typography sx={{ "&:hover": { textDecoration: "underline" } }} variant="body2">
                                                    Shipping & Return Policy
                                                </Typography>
                                            </Link>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Typography
                                variant="h6"
                                sx={{ textDecoration: 'underline' }}
                            >
                                Contact Us
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon sx={{ minWidth: 30 }}>
                                        <LocationOn
                                            sx={{ color: 'white' }}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                Sector I, Jankipuram, Lucknow ,
                                                UP, 226021
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon sx={{ minWidth: 30 }}>
                                        <Email
                                            sx={{ color: 'white' }}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                vegingo.help@gmail.com
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon sx={{ minWidth: 30 }}>
                                        <Phone
                                            sx={{ color: 'white' }}
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                +91 8765126842
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>


            </Box>


        </Box>

    );
};

export default Footer;



