import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LabelIcon from '@mui/icons-material/Label';
import { Link } from 'react-router-dom';

export default function BlogCard({ blog }) {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Card
            sx={{
                height: "100%",
                '&:hover .image': {
                    transform: "scale(1.05)",
                },
                boxShadow:"none",
            }}
        >
            <Box sx={{ overflow: "hidden", borderRadius: 1.5 }}>
                <CardMedia
                    component="img"
                    height="150" // Reduced height
                    image={blog.image}
                    alt={blog.title}
                    className="image"
                    sx={{
                        transition: "transform 0.3s ease-in-out",
                        borderRadius: 1.5,
                    }}
                />
            </Box>
            
            <CardContent sx={{ textAlign: "left", padding: 0 }}>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="left"
                    sx={{ my: 1 }} 
                >
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <PersonIcon color="primary" fontSize="0.7rem" />
                        <Typography sx={{fontWeight:500}} variant="caption" color="text.secondary">
                            {blog.author}
                        </Typography>
                    </Box>

                    <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />

                    <Box display="flex" alignItems="center" gap={0.5}>
                        <CalendarTodayIcon color="primary"  fontSize="0.7rem" />
                        <Typography variant="caption" sx={{fontWeight:500}} color="text.secondary">
                            {formatDate(blog.updated_at)}
                        </Typography>
                    </Box>

                    <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />

                    <Box display="flex" alignItems="center" gap={0.5}>
                        <LabelIcon color="primary"  fontSize="0.7rem" />
                        <Typography variant="caption" sx={{fontWeight:500}} color="text.secondary">
                            {blog.tags}
                        </Typography>
                    </Box>
                </Box>

                <Typography gutterBottom variant="subtitle" sx={{fontWeight:"bold"}}> 
                    {blog.title}
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary" sx={{fontWeight:500}}>
                    {blog.shortDesc}
                </Typography>
            </CardContent>

            <Link to={`/blog/${blog.title}`}>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{ color: "white", mt: 1 }}
                >
                    Read More
                </Button>
            </Link>
        </Card>
    );
}
