import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from '@mui/material/useMediaQuery';
import CategoryFilter from './CategoryFilter';
import CustomSlider from './CustomSlider';
import SectionTitle from '../core/SectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../core/Loading';
import { loadProducts } from '../../redux/slices/productSlice';
import { Alert, Typography } from '@mui/material';
import { Link } from 'react-router-dom';



export default function OurProducts() {
  const { items: products, loading, error } = useSelector(state => state.products);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const dispatch = useDispatch();

  const handleCategoryChange = (category) => {
    setSelectedCategoryId(category);
  };


  useEffect(() => {
    dispatch(loadProducts({ page: 1, limit: 10 }));
  }, [dispatch])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: { xs: 2, md: 5 } }}>
        <Alert severity="error">
          There was an error fetching the products. Please try again later.
        </Alert>
      </Container>
    );
  }




  const filteredProducts = selectedCategoryId === 0 ? products : products.filter(item => item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 2, md:5 } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
        <SectionTitle t1="Our Products" t2="Explore Our Full Range" />
        <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <CategoryFilter selectedCategoryId={selectedCategoryId} onCategoryChange={handleCategoryChange} />
        <Link to={"/shop"}>
        <Typography variant='caption' fontWeight={"bold"} sx={{textTransform:"uppercase",color:"green", "&:hover":{textDecoration:"underline"}}}>See all products &rarr;</Typography>
        </Link>
        </Box>
       
      </Box>

      <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard product={item} />}
        maxSlides={10}
        navigationClass="our-products"
      />


    </Container>
  );
}
