import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useApiFetch from '../hooks/useApiFetch';
import Loading from '../components/core/Loading';
import BlogCard from '../components/blog/BlogCard';
import { api } from '../apiCollection';
import Error from '../components/core/Error';

const tags = ['nutrition', 'health', 'wellness', 'gardening', 'organic', 'sustainability', 'environment', 'farming', 'technology', 'innovation', 'green'];

const BlogPage = () => {
    const allBlogsUrl = api.blog.getAll;
    const { data, loading, error } = useApiFetch(allBlogsUrl);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const blogData = data?.data;

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error code={error.request.status} />;
    }

    const handleTagClick = (tag) => {
        setSelectedTags(prevSelectedTags =>
            prevSelectedTags.includes(tag)
                ? prevSelectedTags.filter(t => t !== tag)
                : [...prevSelectedTags, tag]
        );
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredBlogs = blogData
        ? blogData.filter(blog => {
            const matchesSearch = blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                blog.content.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesTags = selectedTags.length > 0
                ? selectedTags.every(tag => blog.tags.includes(tag))
                : true;
            return matchesSearch && matchesTags;
        })
        : [];

    return (
        <Box minWidth="100%">
            <Container>
                <Grid container sx={{ my: 5 }} spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Search Blogs"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            sx={{ marginBottom: '20px' }}
                        />
                        <Typography variant="h6" component="div" gutterBottom>
                            Tags
                        </Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {tags.map(tag => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onClick={() => handleTagClick(tag)}
                                    color={selectedTags.includes(tag) ? 'primary' : 'default'}
                                    sx={{ color: selectedTags.includes(tag) ? 'white' : 'default' }}
                                />
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {filteredBlogs.length === 0 ? (
                            <Typography textAlign={"center"} variant="h6" color="textSecondary">
                                No blogs found matching the search criteria or selected tags.
                            </Typography>
                        ) : (
                            <Grid columnSpacing={5} rowGap={5} container>
                                {filteredBlogs.map(blog => (
                                    <Grid key={blog.id} item xs={12} md={6} lg={6}>
                                        <BlogCard blog={blog} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default BlogPage;
