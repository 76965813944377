import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    Paper,
    Divider,
    CircularProgress,
    Container,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchProductById, fetchProductBySlug } from "../services/productService";
import Loading from "../components/core/Loading";
import { useTheme } from "@mui/material";
import AddToCartButton from "../components/product/AddToCartButton";
import RatingReview from "../components/product/RatingReview";

const ProductDetailsPage = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState("");
    const slug = location.pathname.split("/").pop();
    const theme = useTheme();

    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            setError("");

            try {
                const data = await fetchProductBySlug(slug);
                console.log(data);
                if (data) {
                    setProduct(data);
                } else {
                    setError("Product not found.");
                }
            } catch (err) {
                setError("An error occurred while fetching the product details.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [slug]);

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        return (
            <Box sx={{ padding: 4, textAlign: "center", display:'flex', justifyContent:"center",alignItems:"center", flexDirection:"column" }}>
                <img src="/product-not-found.webp" width={"500px"}/>
                <Typography variant="h5" fontWeight={"bold"} color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!product) {
        return null;
    }

    return (
        <Container sx={{ my: 4 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            position: "sticky",
                            top: 100,
                            textAlign: "center",
                            borderRadius: 2,
                            overflow: "hidden"
                        }}
                    >
                        <img
                            src={product.image || "https://via.placeholder.com/500"}
                            alt={product.name || "Product"}
                            style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ p: 2,boxShadow:2, borderRadius: 2 }}>

                        <Typography variant="h5" fontWeight={500} gutterBottom>
                            {product.name}
                        </Typography>


                        <Box sx={{ display: "flex", alignItems: "baseline", gap: 2 }}>

                            {
                                product.discounted_price && <Typography sx={{ fontWeight: 500 }} variant={"h5"} color="primary" gutterBottom>
                                    ₹{product.discounted_price || "N/A"}
                                </Typography>
                            }

                            <Typography sx={{ fontWeight: 500, textDecoration: product.discounted_price ? "line-through" : "none", color: product.discounted_price ? "gray" : theme.palette.primary.main }} variant={product.discounted_price ? "body1" : "h5"} color="primary" gutterBottom>
                                ₹{product.price || "N/A"}
                            </Typography>



                        </Box>



                        <Typography variant="body1" gutterBottom color={"GrayText"}>
                            {product.description || "No description available."}
                        </Typography>

                        <AddToCartButton product={product} />

                        <Divider sx={{ my: 2 }} />


                        <Typography variant="h6" gutterBottom>
                            Delivery Information
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {product.deliveryInfo || "Free delivery within 3-5 business days."}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Express delivery available at checkout.
                        </Typography>

                        <Divider sx={{ my: 2 }} />



                        <Typography variant="body2" color="text.secondary">
                            * Prices include all taxes and duties.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <RatingReview productId={product.id} />

        </Container>


    );
};

export default ProductDetailsPage;
