import React, { useEffect, useState } from 'react';
import { Container, Box } from '@mui/material';
import SectionTitle from '../core/SectionTitle';
import CategoryFilter from './CategoryFilter';
import CustomSlider from './CustomSlider';
import ProductCard from '../product/ProductCard';
import { fetchBestSellers } from '../../services/productService'; // Import the service

export default function BestSellers() {
  const [bestSellers, setBestSellers] = useState([]); // Local state for best sellers
  console.log(bestSellers)
  const [status, setStatus] = useState('idle'); // Local status to manage loading state
  const [error, setError] = useState(null); // Local error state
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); // Local category filter state

  useEffect(() => {
    const loadBestSellersData = async () => {
      setStatus('loading');
      try {
        const data = await fetchBestSellers();
        setBestSellers(data);
        setStatus('succeeded');
      } catch (err) {
        setError(err.message);
        setStatus('failed');
      }
    };

    loadBestSellersData();
  }, []); // Empty dependency array to run only once when component mounts

  const handleCategoryChange = (category) => {
    setSelectedCategoryId(category);
  };

  const filteredProducts = selectedCategoryId === 0 ? bestSellers : bestSellers.filter(item => item.category_id === selectedCategoryId);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 2, md: 5 } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
        <SectionTitle t1="Best Sellers" t2="Shop Our Most Popular Items" />
        <CategoryFilter selectedCategoryId={selectedCategoryId} onCategoryChange={handleCategoryChange} />
      </Box>

      <CustomSlider
        items={filteredProducts}
        renderSlide={(item) => <ProductCard product={item} />}
        maxSlides={10}
        navigationClass="best-seller"
      />
    </Container>
  );
}
