import React from 'react';
import { Box, Typography, List, ListItem, Divider, Button, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch } from 'react-redux';
import { downloadInvoice } from '../../redux/slices/orderSlice';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';




const OrderDetails = ({ onClose, order, dateTime }) => {
    const dispatch = useDispatch();

    const handleDownloadInvoice = () => {
        dispatch(downloadInvoice(order.id))


        console.log('Invoice download initiated');
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Typography variant="h6" gutterBottom>
                {order.order_item.length} items in this order
            </Typography>
            <Divider flexItem />

            <List>
                {order.order_item.map((item, index) => (
                    <Box key={index}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Box sx={{ width: 40, height: 40, overflow: "hidden", borderRadius: 2 }}>
                                    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={item.product.image} alt="" />
                                </Box>
                                <Box>
                                    <Typography variant="body2" fontWeight={"bold"}>{item.product.name}</Typography>
                                    <Typography variant="caption" color="textSecondary">{item.quantity} {item.product_unit}</Typography>
                                </Box>

                            </Box>

                            <Box>
                                <Typography variant="body2" fontWeight={"bold"}>₹ {item.product.discounted_price * item.quantity}</Typography>
                                <Typography variant='body2' sx={{ textDecoration: item.product.discounted_price ? "line-through" : "none", color: item.product.discounted_price ? "gray" : "black" }}>₹{item.product.price * item.quantity}</Typography>
                            </Box>

                        </ListItem>
                        {index < order.order_item.length - 1 && <Divider />}
                    </Box>
                ))}
            </List>

            <Divider flexItem />

            <Box sx={{ my: 2 }}>
                <Box sx={{ display: "flex", alignItems: 'center', gap: 1 }}>
                    <ReceiptLongOutlinedIcon />

                    <Typography gutterBottom variant='h6'>Bill Details</Typography>
                </Box>

                <Box sx={{ display: "flex", width: "100%", justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant='body2'>Item total</Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant='caption'>₹{order.subtotal}</Typography>

                    </Box>

                </Box>

                <Box sx={{ display: "flex", width: "100%", justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant='body2'>Delivery Fee</Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant='caption'>{order.shipping_charge ? `₹${order.shipping_charge}` : "FREE"}</Typography>
                    </Box>

                </Box>

                <Box sx={{ display: "flex", width: "100%", justifyContent: 'space-between' }}>
                    <Typography variant='body2'>Discount</Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography color={"primary"} variant='caption'>- ₹{order.discount}</Typography>
                    </Box>

                </Box>
            </Box>

            <Divider flexItem />

            <Box sx={{ my: 2, display: "flex", width: "100%", justifyContent: 'space-between' }}>
                <Typography variant='h6'>Total Bill</Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography fontWeight={"bold"} color={"primary"} variant='body1'>₹{order.grand_total}</Typography>
                </Box>

            </Box>

            <Divider flexItem />

            <Box mt={3}>
                <Typography variant="h6" gutterBottom>
                    Order details
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order ID: ORD{order.id}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Payment: Pay on Delivery
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Deliver to: {order.address.user_name}, {order.address.building_name_area}, {order.address.landmark}, {order.address.city}, {order.address.pincode}, {order.address.country}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order placed on: {dateTime}
                </Typography>
            </Box>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: "wrap" }}>
                <Typography variant="body2">Need help with your order?</Typography>
                <Button variant="contained" sx={{ color: "white" }} color="primary" startIcon={<ShoppingCartIcon />}>
                    Chat with us
                </Button>
            </Box>


            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadInvoice}
                >
                    Download Invoice
                </Button>
            </Box>
        </Box>
    );
};

export default OrderDetails;
