import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import orderService from '../../services/orderService';

// Async Thunks for each order-related action
export const fetchRecentOrders = createAsyncThunk(
    'order/fetchRecentOrders',
    async (_, thunkAPI) => {
        try {
            const response = await orderService.getRecentOrders();

            const data = await response.data.OrderItems;
            return data;
        } catch (error) {
            toast.error('Failed to fetch recent orders');
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchOrderHistory = createAsyncThunk(
    'order/fetchOrderHistory',
    async (_, thunkAPI) => {
        try {
            const response = await orderService.getOrderHistory();

            const data = await response.data.Orders;
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createOrderFromCart = createAsyncThunk(
    'order/createOrderFromCart',
    async (
        { addressId, orderNote, selectedCoupon, deliveryCharge, paymentMethod },
        thunkAPI
    ) => {
        try {
            return await orderService.createOrderFromCart(
                addressId,
                orderNote,
                selectedCoupon,
                deliveryCharge,
                paymentMethod
            );
        } catch (error) {
            toast.error('Failed to create order from cart');
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createOrderFromProduct = createAsyncThunk(
    'order/createOrderFromProduct',
    async ({ productId, quantity }, thunkAPI) => {
        try {
            return await orderService.createOrderFromProduct(
                productId,
                quantity
            );
        } catch (error) {
            toast.error('Failed to create order from product');
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const downloadInvoice = createAsyncThunk(
    "order/downloadInvoice",
    async (orderId, thunkAPI) => {
      try {
        // Call the service function to handle the download
        await orderService.downloadInvoice(orderId);
  
        // Display a success message
        toast.success("Invoice downloaded successfully");
      } catch (error) {
        // Display an error message
        toast.error("Failed to download invoice");
        return thunkAPI.rejectWithValue(error.response?.data || "Error occurred");
      }
    }
  );

// Initial State
const initialState = {
    recentOrders: [],
    orderHistory: [],
    isLoading: false,
    error: null,
    orderNote: '',
    selectedAddress: null,
    billDetails: {},
    selectedCoupon: null,
};

// Order Slice
const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderNote: (state, action) => {
            state.orderNote = action.payload;
        },
        setSelectedAddress: (state, action) => {
            state.selectedAddress = action.payload;
        },
        setBillDetails: (state, action) => {
            state.billDetails = action.payload;
        },
        setSelectedCoupon: (state, action) => {
            state.selectedCoupon = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle fetchRecentOrders
            .addCase(fetchRecentOrders.pending, (state) => {
                state.isLoading = true;
                // toast.info("Fetching recent orders...", {
                //   position: "top-center",
                // });
            })
            .addCase(fetchRecentOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.recentOrders = action.payload;
                // toast.success("Fetched recent orders successfully", {
                //   position: "top-center",
                // });
            })
            .addCase(fetchRecentOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                toast.error('Failed to fetch order history');
            })
            // Handle fetchOrderHistory
            .addCase(fetchOrderHistory.pending, (state) => {
                state.isLoading = true;
                // toast.info("Fetching order history...", {
                //   position: "top-center",
                // });
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orderHistory = action.payload;
                // toast.success("Fetched order history successfully", {
                //   position: "top-center",
                // });
            })
            .addCase(fetchOrderHistory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Handle createOrderFromCart
            .addCase(createOrderFromCart.pending, (state) => {
                state.isLoading = true;
                // toast.info("Creating order from cart...", {
                //   position: "top-center",
                // });
            })
            .addCase(createOrderFromCart.fulfilled, (state, action) => {
                state.isLoading = false;
                state.recentOrders = [];
                state.orderHistory = [];
                state.orderNote = '';
                state.selectedAddress = null;
                state.billDetails = {};
                state.selectedCoupon = null;
                // state.recentOrders.push(action.payload);
                // toast.success("Order created from cart successfully", {
                //   position: "top-center",
                // });
            })
            .addCase(createOrderFromCart.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Handle createOrderFromProduct
            .addCase(createOrderFromProduct.pending, (state) => {
                state.isLoading = true;
                toast.info('Creating order from product...', {
                    position: 'top-center',
                });
            })
            .addCase(createOrderFromProduct.fulfilled, (state, action) => {
                state.isLoading = false;
                state.recentOrders.push(action.payload);
                toast.success('Order created from product successfully', {
                    position: 'top-center',
                });
            })
            .addCase(createOrderFromProduct.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(downloadInvoice.pending, (state) => {
                state.isLoading = true;
                toast.info("Downloading invoice...", { position: "top-center" });
            })
            .addCase(downloadInvoice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(downloadInvoice.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

// Export actions
export const {
    setOrderNote,
    setSelectedAddress,
    setBillDetails,
    setSelectedCoupon,
} = orderSlice.actions;

// Export reducer
export default orderSlice.reducer;
