import axios from "axios";
import api from "../apiCollection";

export const fetchProducts = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(api.products.getAll, { params: { page, limit } });
        return response.data.data.products;
    } catch (error) {
        console.error('Error fetching products:', error.message);
        return [];
    }
};


export const fetchProductById = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.getById}/${id}`);
        return data.data;
    } catch(error){
        console.log('Error fetching product data',error);
    }
}

export const fetchProductBySlug = async(slug)=>{
    try{
        const {data} = await axios.get(`${api.products.getBySlug}/${slug}`);
        return data.data;
    } catch(error){
        console.log('Error fetching product data',error);
    }
}


export const fetchBestSellers = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.bestSellers}`);
        return data.data;
    } catch(error){
        console.log('Error fetching product data',error);
    }
}



export const fetchDealOfTheDay = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.dealOfTheDay}`);
        return data.data;
    } catch(error){
        console.log('Error fetching product data',error);
    }
}

export const fetchNewProducts = async(id)=>{
    try{
        const {data} = await axios.get(`${api.products.newProducts}`);
        return data.data;
    } catch(error){
        console.log('Error fetching product data',error);
    }
}



