import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function CustomSlider({ items, renderSlide, maxSlides = 10, navigationClass }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{
      position: "relative", '& .swiper-scrollbar': {
        backgroundColor: theme.palette.grey[300],
      },
      '& .swiper-scrollbar-drag': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 2,
      }
    }}>
      <Swiper
        spaceBetween={15}
        navigation={isLargeScreen ? {
          nextEl: `.${navigationClass}-next`,
          prevEl: `.${navigationClass}-prev`,
        } : false}
        modules={[Navigation]}
        loop={true}         
        breakpoints={{
          320: { slidesPerView: 2.5, spaceBetween: 20 },
          480: { slidesPerView: 3.5, spaceBetween: 20 },
          768: { slidesPerView: 5, spaceBetween: 20 },
          1024: { slidesPerView: 5, spaceBetween: 20},
        }}
        style={{ width: "100%"}}
      >
        {items.slice(0, maxSlides).map((item, index) => (
          <SwiperSlide key={item.id || index}>
            {renderSlide(item)}
          </SwiperSlide>
        ))}
      </Swiper>

      {isLargeScreen && (
        <>
          <Box
            className={`${navigationClass}-prev`}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              zIndex: 100,
              transform: "translate(-50%,-50%)",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: 1,
            }}
          >
            <KeyboardArrowLeftIcon />
          </Box>

          <Box
            className={`${navigationClass}-next`}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              zIndex: 100,
              transform: "translate(50%,-50%)",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              width: "30px",
              borderRadius: "50%",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: 1,
            }}
          >
            <KeyboardArrowRightIcon />
          </Box>
        </>
      )}
    </Box>
  );
}
