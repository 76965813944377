import React, { useEffect } from 'react';
import Container from "@mui/material/Container";
import CartList from '../components/cart/cart-drawer/CartList';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SimilarProducts from '../components/cart/SimilarProducts';
import { useDispatch, useSelector } from 'react-redux';
import EmptyCart from '../components/cart/EmptyCart';
import { fetchLocalCart, fetchServerCart } from '../redux/slices/cartSlice';
import Loading from "../components/core/Loading"
import { fetchAddress } from '../redux/slices/addressSlice';
import CartTable from '../components/cart/CartTable';
import { useMediaQuery } from '@mui/material';
import CartSummary from '../components/cart/CartSummary';


export default function CartPage() {


  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const {status:addressStatus} = useSelector(state=>state.address);
  
  useEffect(() => {
    if (isAuthenticated ) {
      dispatch(fetchServerCart()); 
      dispatch(fetchAddress());

    } else {
      dispatch(fetchLocalCart()); 
    }
  }, [dispatch]);
  

  
  
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  
  const {status} = useSelector(state=>state.cart);
  const cart = isAuthenticated ? serverCart : localCart;

  if(status === "loading" && addressStatus === "loading"){
    return <Loading/>
  }

  
  const isEmpty = cart.products.length === 0;

 

  return (
    <Box >
      <Container sx={{ minHeight: "100vh", my:5}} maxWidth="lg">

        {
          isEmpty ? <EmptyCart/> : (
            <Grid columnSpacing={2} rowGap={5} container>
            <Grid item md={8} xs={12}>
          
              {
                isSmallScreen? <CartList/> : <CartTable/>
              }
              
              
              <SimilarProducts />
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={2} sx={{ p: 2, position: 'sticky', top: 20 }}>
                <CartSummary/>
  
              </Paper>
            </Grid>
          </Grid>
          )

        }
       
      </Container>
    </Box>
  );
}
