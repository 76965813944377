import React, { useState } from 'react';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import Box from "@mui/material/Box";
import { Link, useNavigate } from 'react-router-dom';
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import { alpha, useTheme } from '@mui/material/styles';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';
import Grid from "@mui/material/Grid";
import { Divider, useMediaQuery } from '@mui/material';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down("sm"));

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(login({ email, password }));
            console.log(result)
            if (login.fulfilled.match(result)) {

                navigate(-1);
            }
        } catch (err) {
            console.error("Login error:", err);
            setError(err.message || 'Invalid email or password');
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    return (
        <Box  sx={{minHeight:"80vh"}}>
            <Container sx={{ my: {xs:1,md:5} }} maxWidth="lg">

                <Grid  container>
                    <Grid item md={6} xs={12}>
                        <Card sx={{ boxShadow: "none" }}>
                            <CardContent>
                                <Typography variant={isSmallScreen?"h5":"h4"} align="center" gutterBottom  fontWeight={500}>
                                    Login to your account
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField                                    
                                        label="Email"
                                        variant="outlined"
                                        size={"small"}
                                        fullWidth
                                        margin="normal"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Mail />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        label="Password"
                                        size={"small"}
                                        type={showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    {error && (
                                        <Typography color="error" variant="body2" align="center">
                                            {error}
                                        </Typography>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                        <Button size='small' type="submit" variant="contained" sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}>
                                            Login
                                        </Button>
                                    </Box>
                                   
                                </form>
                                <Divider flexItem>OR</Divider>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <GoogleLoginButton />
                                </Box>

                                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center", gap:1,mt:3}}>
                                    <Typography variant='body2'>Don't have an account? </Typography>
                                    <Link to={"/account/signup"}>
                                    <Typography variant='body2' sx={{textDecoration:'underline', fontWeight:'bold',"&:hover":{color:"red"}}}>Singup</Typography></Link>
                                </Box>
                                    
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}} item md={6} xs={12}>
                        <Box sx={{width:{md:"400px", xs:"300px"}, height:{md:"400px", xs:"300px"}}}>
                            <img style={{width:"100%",height:"100%"}} src="/login_illustration.jpg" alt="" />
                        </Box>

                    </Grid>
                </Grid>


            </Container>

        </Box>
    );
};

export default LoginPage;
