import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import EjectOutlinedIcon from '@mui/icons-material/EjectOutlined';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Badge } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ThemeToggleButton from '../core/ThemeToggleButton';
import { logout } from '../../redux/slices/authSlice';

const SettingDrawer = ({ open, onClose }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    onClose();
  };

  const listItems = isAuthenticated
    ? [
      {
        title: 'Logout',
        action: handleLogout,
      },
      {
        title:"My Profile",
        link: '/account'

      },
    ]
    : [
      {
        title: 'Login',
        link: '/account/login',
      },
 
      {
        title: 'Register account',
        link: '/account/signUp',
      },
      {
        title: 'My Cart',
        link: '/cart',
      },
   
    ];

  return (
    <Drawer anchor="top" open={open} onClose={onClose}>
      <List>
        {listItems.map((item, index) => (
          <ListItemButton
            key={index}
            component={item.link ? Link : 'div'}
            to={item.link || undefined}
            sx={{ justifyContent: 'center' }}
            onClick={item.action || undefined}
          >
            <ListItem onClick={onClose} sx={{ justifyContent: 'center', width: '100%' }}>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{ align: 'center' }}
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          </ListItemButton>
        ))}
      </List>
      <Box
        sx={{
          pb: 2,
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1">Switch Theme :</Typography>
        <ThemeToggleButton />
      </Box>
    </Drawer>
  );
};

export default function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {isAuthenticated} = useSelector(state=>state.auth);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const localCart = useSelector(state=>state.cart.localCart);
  const serverCart = useSelector(state=>state.cart.serverCart);


  const cart = isAuthenticated ? serverCart : localCart;
  const serverWishlist = useSelector(state=>state.wishlist.serverWishlist);
  const localWishlist = useSelector(state=>state.wishlist.localWishlist);


  const wishlist = isAuthenticated ? serverWishlist : localWishlist;
  const theme = useTheme();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: theme.palette.primary.main,
        zIndex: 1200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 1,
        boxShadow: '0px 0px 20px 5px rgba(0,0,0,0.4)',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
    >
      <Link to="/">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <HomeOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Home
          </Typography>
        </Box>
      </Link>

      <Link to="/cart">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <Badge badgeContent={cart.products.length} color="secondary">
            <ShoppingBagOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
          </Badge>
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
            Cart
          </Typography>
        </Box>
      </Link>


      <Box
        onClick={handleDrawerOpen}
        sx={{
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'column',
        }}
      >
        <SettingsOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
        <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
          Setting
        </Typography>
      </Box>

      <Box
        onClick={handleScrollToTop}
        sx={{
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'column',
        }}
      >
        <EjectOutlinedIcon fontSize="medium" sx={{ color: 'white' }} />
        <Typography variant="caption" sx={{ color: 'white', fontWeight: 500 }}>
          To Top
        </Typography>
      </Box>

      <SettingDrawer open={drawerOpen} onClose={handleDrawerClose} />
    </Box>
  );
}
