import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    useMediaQuery,
    Drawer,
    Divider,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';

const OfferDetails = ({ offer, detailOpen, handleClose }) => {
    const [timeRemaining, setTimeRemaining] = useState('');
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!offer || !detailOpen) return;

        const interval = setInterval(() => {
            const now = new Date();
            const endDate = new Date(offer.end_date);
            const diff = endDate - now;

            if (diff <= 0) {
                setTimeRemaining('Expired');
                clearInterval(interval);
            } else {
                const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((diff / (1000 * 60)) % 60);
                const seconds = Math.floor((diff / 1000) % 60);
                setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [offer, detailOpen]);

    const Content = (
        <>
            <IconButton
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    color: 'white',
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            <Typography
                variant={"h6"}
                sx={{
                    lineHeight:1.2,
                    fontWeight: 'bold',
                    color: "white",
                    mt: 2,
                    marginBottom: '8px',
                }}
            >
                {offer.name}
            </Typography>

            <Divider flexItem variant='middle' sx={{borderColor:"white"}}/>
            <Typography
                color="text.primary"
                variant="body2"
                sx={{ fontWeight: 'bold', color: 'white',mt:1 }}
            >
                {offer.description}
            </Typography>
            <Typography
                sx={{
                    fontSize: '0.6rem',
                    mt: 0.5,
                    color:"white"
                }}
            >
                (Automatically Discounted at checkout)
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2,
                    p: 1,
                    backgroundColor: '#FFF3E0',
                    borderRadius: '4px',
                }}
            >
                <AccessTimeIcon
                    sx={{ fontSize: '1rem', color: '#FF8C00', mr: 1 }}
                />
                <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        color: '#FF8C00',
                        whiteSpace:"nowrap" 
                    }}
                >
                    Expires in: {timeRemaining}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 1,
                    p: 1,
                    backgroundColor: '#E8F5E9',
                    borderRadius: '4px',
                }}
            >
                <CalendarTodayIcon
                    sx={{ fontSize: '1rem', color: '#388E3C', mr: 1 }}
                />
                <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{ fontSize: '0.875rem', color: '#388E3C', fontWeight: "bold", whiteSpace:"nowrap" }}
                >
                    Valid till: {new Date(offer.end_date).toLocaleDateString()}
                </Typography>
            </Box>
        </>
    );

    return isSmallScreen ? (
        <Drawer
            anchor="bottom"
            open={detailOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundImage: 'url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1736581153/vegingo/Untitled_design_3_jt2r3t.jpg)',
                    backgroundPosition:"center",
                    backgroundSize:"cover",
                    borderRadius: '16px 16px 0 0',
                    padding: 2,
                },
            }}
        >
            {Content}
        </Drawer>
    ) : (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: 'url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1736581153/vegingo/Untitled_design_3_jt2r3t.jpg)',
                backgroundPosition:"center",
                    backgroundSize:"cover",
                borderRadius: 2,
                overflow: 'hidden',
                maxHeight: detailOpen ? '100%' : '0px',
                transition: 'max-height 300ms ease-in-out',
                padding: detailOpen ? 2 : 0,
                textAlign: 'center',
                zIndex: 1300,
            }}
        >
            {Content}
        </Box>
    );
};

export default OfferDetails;
