import React from 'react';
import { useLocation } from 'react-router-dom';
import api from '../apiCollection';
import useApiFetch from '../hooks/useApiFetch';
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import ProductCard from '../components/product/ProductCard';
import Loading from '../components/core/Loading';
import Error from '../components/core/Error';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';

const SearchResultPage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');
    const { data: results, loading, error } = useApiFetch(`${api.products.getByName}/${encodeURIComponent(query)}`);

    if (loading) return <Loading />;

    if (error) {
        toast.error("Error fetching product data", {
            position: "top-center"
        })
        return <Error code={error.request.status} />;
    }

    const products = results.data.products;
    console.log(results)
    return (
        <Box sx={{mt:2}}>
            <Container maxWidth="lg" >
                <Typography variant='h4' color={"primary"}>Search results for '{query}'</Typography>
                {results && products.length > 0 ? (
                    <Grid container columnSpacing={{ xs: 2, md: 5 }} rowGap={5} sx={{ my: 5 }}>
                        {products.map((item) => (
                            <Grid key={item.id} md={3} xs={6} item>
                                <ProductCard product={item} />
                            </Grid>

                        ))}
                    </Grid>
                ) : (
                    <p>No results found.</p>
                )}

            </Container>
        </Box>







    );
};

export default SearchResultPage;
