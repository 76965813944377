import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import contactService from "../services/contactService";
import { toast } from "react-toastify";

export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = { name: "", email: "", subject: "", message: "" };
    let isValid = true;

    if (!formData.name.trim()) {
      tempErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email address is invalid";
      isValid = false;
    }

    if (!formData.subject.trim()) {
      tempErrors.subject = "Subject is required";
      isValid = false;
    }

    if (!formData.message.trim()) {
      tempErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await contactService.submitContactForm(
        formData.name,
        formData.email,
        formData.subject,
        formData.message
      );
      console.log("Form submitted successfully", response);
      toast.success("Form submitted successfully", {
        position: "top-center",
      });

      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", { position: "top-center" });
    }
  };

  return (
    <Container sx={{ minHeight: "80vh", py: 4 }} maxWidth="lg">
      <Box>
        <Typography
          variant="h4"
          component="h1"
          textAlign="center"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ color: "text.secondary", mb: 4 }}
        >
          We'd love to hear from you! Feel free to get in touch with us.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {/* Illustration Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { md: 500, xs: 300 },
                height: { md: 500, xs: 300 },
                mb: { xs: 4, md: 0 },
              }}
            >
              <img
                loading="lazy"
                src="/contact_illustration.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 5,
                }}
                alt="Contact Illustration"
              />
            </Box>
          </Grid>

          {/* Form Section */}
          <Grid sx={{display:"flex", justifyContent:"center", alignItems:"center"}} item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* Name */}
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>

                  {/* Email */}
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>

                  {/* Subject */}
                  <Grid item xs={12}>
                    <TextField
                      id="subject"
                      label="Subject"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      value={formData.subject}
                      onChange={handleChange}
                      error={!!errors.subject}
                      helperText={errors.subject}
                    />
                  </Grid>

                  {/* Message */}
                  <Grid item xs={12}>
                    <TextField
                      id="message"
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      required
                      size="small"
                      value={formData.message}
                      onChange={handleChange}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12} textAlign="center">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        background: "linear-gradient(to right, #4CAF50, #81C784)",
                        color: "white",
                        px: 4,
                        py: 1.5,
                        fontWeight: "bold",
                        "&:hover": {
                          background:
                            "linear-gradient(to right, #388E3C, #66BB6A)",
                        },
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
