import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Divider,
  ListItemButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAddress } from "../../redux/slices/orderSlice";
import AddressFormFields from "../account/AddressFormFields";

const AddressModal = ({ selectedAddressId, onChange, open, onClose }) => {
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => state.address);

  const handleClose = () => {
    onClose(false);
    setShowForm(false);
  };


  const handleSelectAddress = (addressId) => {
    dispatch(setSelectedAddress(addressId));
    onChange(addressId);
    handleClose();
  }

 

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="address-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { md: 400, xs: 350 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        {/* Header */}

        <Typography variant="h6" fontWeight="bold">
          {showForm ? "Add New Address" : "Select an Address"}
        </Typography>
        <IconButton onClick={() => onClose(false)} sx={{ position: "absolute", top: 8, right: 8 }} >
          <CloseIcon fontSize='small' />
        </IconButton>

        <Divider />

        {showForm ? (
       <AddressFormFields onClose={()=>setShowForm(false)}/>
        ) : (
          // Saved Addresses List
          <Box mt={2}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              sx={{ color: "red", fontWeight: "bold", mb: 2 }}
              onClick={() => setShowForm(true)}
            >
              Add Address
            </Button>

            {
              addresses.length > 0 ? (
                <>
                  <Typography variant="subtitle2" fontWeight="bold">
                    SAVED ADDRESSES
                  </Typography>
                  <Typography color="green" fontSize="0.85rem" mb={1}>
                    Nearby address(es)
                  </Typography>
                  <Divider />
                  <List sx={{ height: "200px", overflow: "auto" }} disablePadding>
                    {addresses.map((address) => (
                      <ListItem disablePadding key={address.id} >
                        <ListItemButton
                          onClick={() => handleSelectAddress(address.id)}
                          sx={{
                            backgroundColor:
                              selectedAddressId === address.id
                                ? "rgba(0, 128, 0, 0.1)"
                                : "transparent",
                            "&:hover": {
                              backgroundColor:
                                selectedAddressId === address.id
                                  ? "rgba(0, 128, 0, 0.15)"
                                  : "rgba(0, 0, 0, 0.04)",
                            },
                            borderRadius: 1,
                          }}
                        >
                          <LocationOnIcon sx={{ mr: 1 }} />
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontSize: "0.9rem", fontWeight:"bold" }}
                              >
                                {address.buildingNameArea}
                                <br />
                                near {address.landmark}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography gutterBottom
                                  sx={{ fontSize: "0.85rem" }}
                                >
                                  {address.city}, {address.pincode}, {address.state}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "0.85rem" }}
                                >
                                  {address.user_name} <br />
                                  Phone number: {address.phone_number}
                                </Typography>

                              </>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </>


              ) : (
                <Typography sx={{ my: 2 }}>No saved address</Typography>
              )
            }

          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddressModal;
